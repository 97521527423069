import React from "react"
import Image from "../image"

function Gallery(props) {
  let { gallery } = props

  // console.log(gallery.length)

  return (
    <>
      {gallery && (
        <div
          className={`row grid-items grid gallery gallery-${gallery?.length}`}
        >
          <div className="grid-sizer"></div>
          {!!gallery &&
            gallery.map((post, index) => {
              post.node = post.image
              // console.log(post)
              return (
                <a
                  href={post?.image?.localFile?.publicURL}
                  className={`post ${
                    post?.layout !== "none" ? post?.layout : ""
                  } `}
                  key={post?.image?.databaseId}
                  data-attribute="SRL"
                >
                  {/* <cite>{index + 1}</cite> */}
                  <Image
                    data={post}
                    title="Click to Zoom"
                    classes="zoom"
                    placeholderClassName="placeholder"
                  />
                </a>
              )
            })}
        </div>
      )}
    </>
  )
}

export default Gallery
