import { motion, useTransform, useViewportScroll } from "framer-motion"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect } from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Gallery from "../components/Gallery/gallery"
import Image from "../components/image"
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"

const Isotope = typeof window !== `undefined` ? require("isotope-layout") : null
const imagesLoaded = require("imagesloaded")

let ProjectTemplate = ({ data: { previous, next, post }, location }) => {
  let nextService = next?.taxServices?.nodes[0]?.slug || ""
  let nextSector = next?.taxSectors?.nodes[0]?.slug || ""
  let previousService = previous?.taxServices?.nodes[0]?.slug || ""
  let previousSector = previous?.taxSectors?.nodes[0]?.slug || ""

  let {
    title,
    content,
    seo,
    featuredImage,
    taxServices,
    taxSectors,
    acf_project_fields: { place, size, year, category, scope },
    acf_gallery: { gallery },
  } = post

  let pathname = "/" + taxServices?.nodes[0]?.slug + "/"
  location = { ...location, pathname: pathname }

  let service = taxServices?.nodes[0]?.slug
  let sector = taxSectors?.nodes[0]?.slug

  // console.log(nextSector)

  useEffect(() => {
    // console.log("did mount")
    if (typeof window === `undefined`) {
      return
    }
    var grid = document.querySelector(".grid-items")
    // var iso = undefined
    let iso = new Isotope(grid, {
      layoutMode: "masonry",
      masonry: {
        columnWidth: ".grid-sizer",
        gutter: 0,
      },
      itemSelector: ".post",
      percentPosition: true,
    })
    // iso.arrange({ filter: `.post` })
    imagesLoaded(grid).on("progress", function () {
      // layout Isotope after each image loads
      iso.layout()
      iso.arrange({ filter: `.post` })
    })

    /* if (typeof window !== `undefined`) {
      let el = document.getElementsByTagName("html")[0]
      el.classList.add("ss")
    } */
  })

  // console.log(taxServices.nodes[0].slug)

  const options = {
    settings: {
      disableWheelControls: true,
      overlayColor: "#323537",
      autoplaySpeed: 5000,
      transitionSpeed: 5000,
    },
    buttons: {
      showDownloadButton: false,
      showThumbnailsButton: false,
      showAutoplayButton: false,
      showFullscreenButton: false,
      backgroundColor: "#323537",
    },
    progressBar: {
      backgroundColor: "#848f8a",
      fillColor: "#323537",
      height: "1px",
      showProgressBar: true,
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: "center",
      thumbnailsGap: "4px 1px",
      thumbnailsOpacity: 0.5,
      thumbnailsSize: ["100px", "80px"],
    },
    caption: {
      showCaption: false,
    },
  }

  // Framer motion

  const frame = {}

  const frameVariant = {
    hidden: { marginTop: 0 },
    visible: {
      marginTop: -180,
      transition: {
        delay: 0,
        ease: "linear",
      },
    },
  }

  const frameVariantTitle = {
    hidden: { top: 10, opacity: 0 },
    visible: {
      opacity: 1,
      top: 0,
      transition: {
        delay: 0.7,
        ease: "linear",
      },
    },
  }

  const frameVariantLocation = {
    hidden: { top: 50, opacity: 0 },
    visible: {
      opacity: 1,
      top: 0,
      transition: {
        delay: 1,
        ease: "linear",
      },
    },
  }

  const { scrollYProgress } = useViewportScroll()
  const up = useTransform(
    scrollYProgress,
    [0, 0.2, 0.4, 0.6],
    ["0%", "15%", "30%", "45%"]
  )

  // Framer motion

  return (
    <Layout location={location}>
      <SEO bodyClasses="project fixed-layout" seo={seo} />
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <motion.div className="col-md-12 herowrapper">
                <motion.div className="heroimage" style={{ top: 0, y: up }}>
                  <Image
                    data={featuredImage}
                    classes="featured-image"
                    placeholderClassName="placeholder"
                    title="Click to Zoom"
                    data-attribute="SRL"
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>

          <motion.div variants={frame} initial="hidden" animate="visible">
            <motion.div
              className="container-fluid content"
              id="projectinfo"
              variants={frameVariant}
            >
              <div className="row" id="casestudy">
                <div className="col-md-12 relative text-center">
                  <Link to={`${pathname}#${sector}`} className="goback">
                    <span className="icon">←</span>
                    <span className="title">
                      Back to {taxServices.nodes[0].name}
                    </span>
                  </Link>
                  <p>
                    <a
                      href="#casestudy"
                      className="scroll-link"
                      title="Scroll down"
                    >
                      <motion.span
                        className="scroll social social-angle-left"
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          top: 150,
                          transitionEnd: { duration: 3, opacity: 0 },
                        }}
                        transition={{
                          delay: 3,
                          duration: 1.5,
                          ease: [0.6, -0.05, 0.01, 0.9],
                        }}
                      ></motion.span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-7 col-lg-6" id="project-heading">
                  {!!title && (
                    <motion.h2 className="title" variants={frameVariantTitle}>
                      {parse(title)}
                    </motion.h2>
                  )}
                  {!!place && (
                    <motion.h3
                      className="place"
                      variants={frameVariantLocation}
                    >
                      {parse(place)}
                    </motion.h3>
                  )}
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="title">
                    <ul className="attributes">
                      {!!scope && (
                        <li className="scope">
                          <span className="social social-scope"></span>
                          <strong>Scope</strong> <em>{parse(scope)}</em>
                        </li>
                      )}
                      {!!category && (
                        <li className="category">
                          <span className="social social-category"></span>
                          <strong>Category</strong> <em>{parse(category)}</em>
                        </li>
                      )}
                      {!!size && (
                        <li className="size">
                          <span className="social social-size"></span>
                          <strong>Size</strong> <em>{parse(size)}</em>
                        </li>
                      )}
                      {!!year && (
                        <li className="year">
                          <span className="social social-year"></span>
                          <strong>Year</strong> <em>{parse(year)}</em>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <Gallery gallery={gallery} />
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7 project-details">
                  {parse(content)}
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-12">
                  <nav className="blog-post-nav">
                    <ul className="projects-nav">
                      <li>
                        {previous &&
                          (sector === previousSector ||
                            service === "master-planning") &&
                          service === previousService && (
                            <Link to={previous.uri} rel="prev">
                              <span className="icon">←</span>{" "}
                              {parse(previous.title)}
                            </Link>
                          )}
                      </li>

                      <li>
                        {next &&
                          (sector === nextSector ||
                            service === "master-planning") &&
                          service === nextService && (
                            <Link to={next.uri} rel="next">
                              {parse(next.title)}{" "}
                              <span className="icon">→</span>
                            </Link>
                          )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpProject(id: { eq: $id }) {
      taxServices {
        nodes {
          name
          slug
        }
      }
      taxSectors {
        nodes {
          name
          slug
        }
      }
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      acf_project_fields {
        place: location
        client
        size
        year
        cost
        category
        scope
      }
      featuredImage {
        node {
          ...fragmentImage
        }
      }
      acf_gallery {
        gallery {
          image {
            ...fragmentImage
          }
          layout
        }
      }
      seo {
        ...WpYoastSEO
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpProject(id: { eq: $previousPostId }) {
      uri
      title
      taxSectors {
        nodes {
          name
          slug
        }
      }
      taxServices {
        nodes {
          name
          slug
        }
      }
    }

    # this gets us the next post by id (if it exists)
    next: wpProject(id: { eq: $nextPostId }) {
      uri
      title
      taxSectors {
        nodes {
          name
          slug
        }
      }
      taxServices {
        nodes {
          name
          slug
        }
      }
    }
  }
`
